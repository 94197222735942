<app-breadcrumb [title]="'إدارة المؤشرات'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل مؤشر موجودة</h5>
            يمكنك تعديل مؤشر أو أكثر فى المؤشرات
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة مؤشر جديد</h5>
            يمكنك إضافة مؤشر أو أكثر فى المؤشرات
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
            [ngClass]="{'was-validated': validated}">

            <div class="row">
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم باللغة العربية</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="TitleAr" />




              </div>
           
             
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم باللغة الانجليزية</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="Title" />




              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الترتيب</label>
                <input class="form-control" id="validationCustom01" type="number" placeholder="" required=""
                  formControlName="Order" />




              </div>

            </div>

          

            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>