<app-breadcrumb [title]="'إدارة طلبات التوظيف'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل طلب توظيف موجودة</h5>
            يمكنك تعديل طلب توظيف أو أكثر فى الوظائف
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة طلب توظيف جديد</h5>
            يمكنك إضافة طلب توظيف أو أكثر فى الوظائف
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
            [ngClass]="{'was-validated': validated}">

            <div class="row">
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم </label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="Name" />




              </div>
           
             
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الموبايل</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="Mobile" />




              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> البريد الالكتروني</label>
                <input class="form-control" id="validationCustom01" type="email" placeholder="" required=""
                  formControlName="Email" />




              </div>

              <div class="col-6 mb-3">

                <label for="validationCustom01"> الوظيفة</label>
                  <ng-select [items]="Career" placeholder="Select"
                    class="js-example-placeholder-multiple" bindLabel="jobTitle"
                    bindValue="id" formControlName="CareerId"></ng-select>
                


              </div>
     
              <div class="col-6 mb-3">

                <label for="validationCustom01"> السيرة الذاتية</label>
                <!-- <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                 /> -->

                  <ngx-dropzone  (change)="onSelect($event)" [multiple]="false">
                    <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="icon-cloud-up"></i>
                          <h6>Drop CV here or click to upload.</h6>       
                                         </div>
                    </ngx-dropzone-label>
                    <ng-container *ngIf="!HaveResumeDoc&&isImage">

                    
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"  [removable]="true" (removed)="onRemove(f)">

</ngx-dropzone-image-preview>
</ng-container>
<ng-container *ngIf="!isImage && files.length!=0">

                    
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
		<ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
	</ngx-dropzone-preview>
    </ng-container>
<img class="imgfromUrl" *ngIf="HaveResumeDoc && isImage" [src]="CareerRequestData.resume" alt="Image">
                </ngx-dropzone>

              </div>
            </div>

          

            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>