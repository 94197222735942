<app-breadcrumb [title]="'إدارة الوظائف'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل وظيفة موجودة</h5>
            يمكنك تعديل وظيفة أو أكثر فى الوظائف
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة وظيفة جديد</h5>
            يمكنك إضافة وظيفة أو أكثر فى الوظائف
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
            [ngClass]="{'was-validated': validated}">

            <div class="row">
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم الوظيفة بالعربية</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="JobTitleAr" />




              </div>
           
             
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم الوظيفة بالانجليزية</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="JobTitle" />




              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> متطلبات الوظيفة بالعربية</label>
                <textarea class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="JobRequirementsAr" ></textarea>




              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> متطلبات الوظيفة بالانجليزية</label>
                <textarea class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="JobRequirements" ></textarea>




              </div>
           
             
              <div class="col-6 mb-3">

                <label for="validationCustom01"> عدد الوظائف الشاغرة</label>
                <input class="form-control" id="validationCustom01" type="number" placeholder="" required=""
                  formControlName="NumOfVacancies" />




              </div>
              <div class="col-6 mb-3">
                <label for="validationCustom01"> قطاع الخدمات</label>
                  <ng-select [items]="ServiceSectors" placeholder="Select"
                    class="js-example-placeholder-multiple" bindLabel="title"
                    bindValue="id" formControlName="ServiceSectorId"></ng-select>
              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الترتيب</label>
                <input class="form-control" id="validationCustom01" type="number" placeholder="" required=""
                  formControlName="Order" />
                  <br>
                  <label for="validationCustom01"> العرض في الموقع</label>
                  <div class="media">
                    <div class="media-body  icon-state">
                      <label class="switch">
                        <input type="checkbox" formControlName="WebsiteVisible" checked=""><span class="switch-state"></span>
                      </label>
                    </div>
                  </div>



              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> صورة للوظيفة</label>
                <!-- <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                 /> -->

                  <ngx-dropzone  (change)="onSelect($event)" [multiple]="false">
                    <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="icon-cloud-up"></i>
                          <h6>Drop image here or click to upload.</h6>                        </div>
                    </ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"  [removable]="true" (removed)="onRemove(f)">

</ngx-dropzone-image-preview>
<img class="imgfromUrl" *ngIf="HaveCareersImage" [src]="Career.image" alt="Image">

                </ngx-dropzone>

              </div>
            </div>

          

            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>