import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ServiceSector} from 'src/app/shared//Models/ServiceSector'
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import {ServiceSectorService} from 'src/app/shared/API-Service/service-sector.service';
@Component({
  selector: 'app-insert-service-sector',
  templateUrl: './insert-service-sector.component.html',
  styleUrls: ['./insert-service-sector.component.scss']
})
export class InsertServiceSectorComponent {
  InsertForm: FormGroup;
  ServiceSector=new ServiceSector()
  update: boolean;
  validated: boolean;
  ServiceSectorData: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];
  filesIcon: File[] = [];

  ServiceSectorImageView:any[]=[];
  ServiceSectorIconView:any[]=[];

  ServiceSectorForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveServiceSectorImage: boolean=false;
  HaveServiceSectorIcon:boolean=false
  ServiceSectors: any[];
  constructor(private ServiceSectorService :ServiceSectorService, private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder) {
    this.InitForm()
    this.GetServiceSector()
   }
   GetServiceSector(){
    this.ServiceSectorService.GetServiceSectors().subscribe(res=>{
      this.ServiceSectors=res.data
    })
   }
   
  onSelect(event) {

    this.files.push(...event.addedFiles);
    this.HaveServiceSectorImage=false
    this.ServiceSectorImageView.push(this.files[0])
 
    if(this.files.length > 1){
      this.replaceFile();
    }
  
  }
  
  replaceFile() {
   this.files.splice(0,1);
   this.HaveServiceSectorImage=false
   this.ServiceSectorImageView.splice(0.1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    if(this.ServiceSectorData.image){
          this.HaveServiceSectorImage=true

    }
    this.ServiceSectorImageView.splice(this.ServiceSectorImageView.indexOf(event), 1);

  }
  onSelectIcon(event) {

    this.filesIcon.push(...event.addedFiles);
    this.HaveServiceSectorIcon=false
    this.ServiceSectorIconView.push(this.filesIcon[0])
 
    if(this.filesIcon.length > 1){
      this.replaceFileIcon();
    }
  
  }
  
  replaceFileIcon() {
   this.filesIcon.splice(0,1);
   this.HaveServiceSectorIcon=false
   this.ServiceSectorIconView.splice(0.1);
  }

  onRemoveIcon(event) {
    this.filesIcon.splice(this.filesIcon.indexOf(event), 1);
    if(this.ServiceSectorData.icon){
          this.HaveServiceSectorIcon=true

    }
    this.ServiceSectorIconView.splice(this.ServiceSectorIconView.indexOf(event), 1);

  }
 ngOnInit(): void {
   if (this.route.snapshot.paramMap.get("id")) {
  
     this.id =this.route.snapshot.paramMap.get("id")
   this.ServiceSectorService.GetServiceSectorById(this.id).subscribe(async (res:any)=>{
   this.ServiceSectorData=  await res.data;
  this.ServiceSectorData.image=this.imgUrlServer+this.ServiceSectorData.image
  this.ServiceSectorData.icon=this.imgUrlServer+this.ServiceSectorData.icon

  if(this.ServiceSectorData.image){
   this.HaveServiceSectorImage=true
  }
  if(this.ServiceSectorData.icon){
    this.HaveServiceSectorIcon=true;
  }
   this.InitForm(this.ServiceSectorData);
   })
     this.update = true;
   } else {
     this.InitForm()
     this.update = false;
   }
 }
 InitForm(ServiceSector?:ServiceSector) {
   this.InsertForm = this._formBuilder.group({
    TitleAr: [ServiceSector?.titleAr, Validators.required],
    Title: [ServiceSector?.title, Validators.required],
    Order: [ServiceSector?.order, Validators.required],

   });
   }
   get fc() {
     return this.InsertForm.controls;
   }
 onSubmit() {
   
   if (this.InsertForm.status == "VALID") {
   this.validated = false;
   if (this.update == true) {
   this.UpdateServiceSector();
   } else {
   this.InsertServiceSector();
   }
   } else {
   this.validated = true;
   }
   }
   UpdateServiceSector(){
     this.disableBtn=true;
     this.ServiceSectorImageView.forEach(element => {
       this.ServiceSectorForm.set('ServiceSectorImage', element)
     })
     this.ServiceSectorIconView.forEach(element => {
      this.ServiceSectorForm.set('ServiceSectorIcon', element)
    })
     this.ServiceSectorForm.set('Id',this.id)
     this.ServiceSectorForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.ServiceSectorForm.set('Title',this.InsertForm.get('Title').value)
     this.ServiceSectorForm.set('Order',this.InsertForm.get('Order').value)

     this.ServiceSectorService.UpdateServiceSector(this.ServiceSectorForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم التعديل بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-portfolio");
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
   
       );
   }
   InsertServiceSector(){
     this.disableBtn=true;
     this.ServiceSectorImageView.forEach(element => {
      this.ServiceSectorForm.set('ServiceSectorImage', element)
    })
    this.ServiceSectorIconView.forEach(element => {
     this.ServiceSectorForm.set('ServiceSectorIcon', element)
   })
     this.ServiceSectorForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.ServiceSectorForm.set('Title',this.InsertForm.get('Title').value)
     this.ServiceSectorForm.set('Order',this.InsertForm.get('Order').value)
 
     this.ServiceSectorService.InsertServiceSector(this.ServiceSectorForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم الحفظ بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-portfolio");
 
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
             );
   }
}
