import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  constructor(private http:HttpClient,private router:Router) { }
  GetPortfolio(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Portfolio/GetDB`);
  }
  GetPortfolioPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Portfolio/GetPagedDB`,data);
  }
  GetPortfolioById(PortfolioId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Portfolio/GetByIdDB?Id=${PortfolioId}`);
  }
  InsertPortfolio(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Portfolio/Create`,Data);
  }
  UpdatePortfolio(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Portfolio/Update`,Data);
  }
  
  DeletePortfolio(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Portfolio/Delete?id=${Id}`);
  }
}
