import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import {CareerService} from 'src/app/shared/API-Service/career.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-career',
  templateUrl: './list-career.component.html',
  styleUrls: ['./list-career.component.scss']
})
export class ListCareerComponent extends BaseComponent implements OnInit {
  Careers: any;

  constructor(private router: Router,private CareerService:CareerService) { super()
  
    
  }

  ngOnInit(): void {
  this.GetCareerList();
  }

  AddNew(){
    this.router.navigateByUrl("content/admin/insert-career");
  }
  pageChanged(event:any){
    this.pageNumber = event;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
this.GetCareerList();
   
  }
  update(id){
    this.router.navigate([`content/admin/update-career`, id]);
  }
  GetCareerList(){
    this.CareerService.GetCareersPaged(this.pager).subscribe(
      (res:any) => {
        this.Careers=res.data.items;
        this.totalCount = res.data.totalCount;
        
      },
      err => {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: err.error,
        // })
      }
    )
  }
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.CareerService.DeleteCareer(id).subscribe(
            response => {
              this.GetCareerList();
              Swal.fire({
                icon: 'success',
                title: "تم الحذف بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
}
