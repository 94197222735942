import { Directive, ElementRef, OnInit ,Renderer2} from '@angular/core';
import { AngularEditorValidateService } from 'src/app/shared/API-Service/angular-editor-validate.service'; // Import your service here

@Directive({
  selector: '[appAngularEditorValidate]'
})
export class AngularEditorValidateDirective {

  constructor(private elementRef: ElementRef, private renderer: Renderer2,private AngularEditorValidateService: AngularEditorValidateService) {
//  console.log(this.AngularEditorValidateService.value)
 console.log(this.elementRef)   
  }

}
