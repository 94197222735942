import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Faq} from 'src/app/shared//Models/Faq'
import Swal from 'sweetalert2';
import { FaqService } from 'src/app/shared/API-Service/faq.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-insert-Faq',
  templateUrl: './insert-Faq.component.html',
  styleUrls: ['./insert-Faq.component.scss']
})
export class InsertFaqComponent {
  InsertForm: FormGroup;
  Faq=new Faq()
  maxDate: Date;
  update: boolean;
  validated: boolean;
  FaqData: any;
  id: any;
  disableBtn:boolean
  imgUrlServer=environment.Server_URL_Image+'/'
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private FaqService:FaqService) {
    this.InitForm()
   }
 
 
 
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.FaqService.GetFaqById(this.id).subscribe(async (res:any)=>{
    this.FaqData=  await res.data;
 
    this.InitForm(this.FaqData);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(Faq?:Faq) {
    this.InsertForm = this._formBuilder.group({
      Question: [Faq?.question, Validators.required],
      QuestionAr: [Faq?.questionAr, Validators.required],
      Answer: [Faq?.answer, Validators.required],
      AnswerAr: [Faq?.answerAr, Validators.required],
      Order: [Faq?.order, Validators.required],

    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    if (this.update == true) {
    this.UpdateContactInquiry();
    } else {
    this.InsertContactInquiry();
    }
    } else {
    this.validated = true;
    }
    }
    UpdateContactInquiry(){
      this.disableBtn=true;
      const data= {
        "id":this.id,
        "questionAr": this.InsertForm.get('QuestionAr').value,
        "question": this.InsertForm.get('Question').value,
        "answerAr": this.InsertForm.get('AnswerAr').value,
        "answer": this.InsertForm.get('Answer').value,
        "order": this.InsertForm.get('Order').value,

      }
      this.FaqService.UpdateFaq(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم التعديل بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-faq");
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
    
        );
    }
    InsertContactInquiry(){
      this.disableBtn=true;
     
  const data= {
    "questionAr": this.InsertForm.get('QuestionAr').value,
    "question": this.InsertForm.get('Question').value,
    "answerAr": this.InsertForm.get('AnswerAr').value,
    "answer": this.InsertForm.get('Answer').value,
    "order": this.InsertForm.get('Order').value,

  }
      this.FaqService.InsertFaq(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-faq");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
}
