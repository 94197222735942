import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  RoleTitle: any;

  constructor(public router: Router) {
    console.log(JSON.parse(localStorage.getItem("UserData")))
    const userData=JSON.parse(localStorage.getItem("UserData"))
    if (userData) {
     this.userName=userData.name
     this.RoleTitle=userData.roleTitle
    } else {
      console.log("NO ");
    }
  }

  ngOnInit() {}

  logoutFunc() {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }
  changePassword(){
    this.router.navigateByUrl('content/admin/change-password');
  }
}
