import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent {
  firstPage = { page: 1 };
  totalCount = 0;
  pageNumber = 1;
  pager: any = {
    maxResultCount: 25,
    skipCount: 0
  };


  constructor() { }

}
