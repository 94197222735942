import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CareerRequestService {
  constructor(private http:HttpClient,private router:Router) { }
  GetCareerRequest(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/CareerRequest/GetDB`);
  }
  GetCareerRequestPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/CareerRequest/GetPagedDB`,data);
  }
  GetCareerRequestById(CareerRequestId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/CareerRequest/GetByIdDB?Id=${CareerRequestId}`);
  }
  InsertCareerRequest(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/CareerRequest/Create`,Data);
  }
  UpdateCareerRequest(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/CareerRequest/Update`,Data);
  }
  
  DeleteCareerRequest(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/CareerRequest/Delete?id=${Id}`);
  }
}
