import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Ticker} from 'src/app/shared//Models/Ticker'
import Swal from 'sweetalert2';
import { TickerService } from 'src/app/shared/API-Service/ticker.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-insert-ticker',
  templateUrl: './insert-ticker.component.html',
  styleUrls: ['./insert-ticker.component.scss']
})
export class InsertTickerComponent {
  InsertForm: FormGroup;
  Ticker=new Ticker()
  maxDate: Date;
  update: boolean;
  validated: boolean;
  TickerData: any;
  id: any;
  disableBtn:boolean
  imgUrlServer=environment.Server_URL_Image+'/'
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private TickerService:TickerService) {
    this.InitForm()
   }
 
 
 
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.TickerService.GetTickerById(this.id).subscribe(async (res:any)=>{
    this.TickerData=  await res.data;
 
    this.InitForm(this.TickerData);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(Ticker?:Ticker) {
    this.InsertForm = this._formBuilder.group({
    Title: [Ticker?.title, Validators.required],
    TitleAr: [Ticker?.titleAr, Validators.required],
    Order: [Ticker?.order, Validators.required],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    if (this.update == true) {
    this.UpdateTicker();
    } else {
    this.InsertTicker();
    }
    } else {
    this.validated = true;
    }
    }
    UpdateTicker(){
      this.disableBtn=true;
      const data= {
        "id":this.id,
        "title": this.InsertForm.get('Title').value,
        "titleAr": this.InsertForm.get('TitleAr').value,
        "order": this.InsertForm.get('Order').value,
      }
      this.TickerService.UpdateTicker(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم التعديل بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-ticker");
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
    
        );
    }
    InsertTicker(){
      this.disableBtn=true;
     
  const data= {
    "title": this.InsertForm.get('Title').value,
    "titleAr": this.InsertForm.get('TitleAr').value,
    "order": this.InsertForm.get('Order').value,
  }
      this.TickerService.InsertTicker(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-ticker");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
}
