import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import {ContactInquiryService} from 'src/app/shared/API-Service/contact-inquiry.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment.prod';
import { elementAt } from 'rxjs';
@Component({
  selector: 'app-list-contact-inquiry',
  templateUrl: './list-contact-inquiry.component.html',
  styleUrls: ['./list-contact-inquiry.component.scss']
})
export class ListContactInquiryComponent extends BaseComponent implements OnInit{
  ContactInquiry: any;
  imgUrlServer=environment.Server_URL_Image+'/'
  StatesValue:any
  @ViewChildren('checkboxRef') checkboxes: QueryList<ElementRef>;
  States:any[]=[]
  constructor(private router: Router,private ContactInquiryService:ContactInquiryService) { super()
   this.States= [
      {
        id:1,
        title:"تمت المشاهدة"
      },
      {
        id:2,
        title:"لم تتم المشاهدة"
      },
      ]
    
  }
  chenged(id){
    if(id==1){
      this.pager.isViewed=true

    }
    else if (id==2){
      this.pager.isViewed=false

    }
    else{
      this.pager.isViewed=null

    }
this.GetContactInquiryList();
  }
  onCheckboxChange(item: any,index) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد تغير الحالة الي تمت المشاهدة ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'تأكيد',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          this.ContactInquiryService.MarkAsViewed(item.id).subscribe(
            response => {
              this.GetContactInquiryList();
              Swal.fire({
                icon: 'success',
                title: "تم تغيير الحالة الي تمت المشاهدة بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          console.log(this.checkboxes)
    const ItemCheckbox=      this.checkboxes.find(x=>x.nativeElement.id==item.id)
    if(ItemCheckbox){
      ItemCheckbox.nativeElement.checked = false;
    }
          }
      });
  }
  ngOnInit(): void {
  this.GetContactInquiryList();
  }

  AddNew(){
    this.router.navigateByUrl("content/admin/insert-Career-request");
  }
  pageChanged(event:any){
    this.pageNumber = event;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
this.GetContactInquiryList();
   
  }
  update(id){
    this.router.navigate([`content/admin/update-Career-request`, id]);
  }
  GetContactInquiryList(){
    this.ContactInquiryService.GetContactInquiryPaged(this.pager).subscribe(
      (res:any) => {
        this.ContactInquiry=res.data.items;
        this.totalCount = res.data.totalCount;
        
      },
      err => {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: err.error,
        // })
      }
    )
  }
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.ContactInquiryService.DeleteContactInquiry(id).subscribe(
            response => {
              this.GetContactInquiryList();
              Swal.fire({
                icon: 'success',
                title: "تم الحذف بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
}
