import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from '../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private http:HttpClient,private router:Router) { }
 
  GetFaq(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Faq/GetDB`);
  }
  GetFaqPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Faq/GetPagedDB`,data);
  }
  GetFaqById(FaqId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Faq/GetByIdDB?Id=${FaqId}`);
  }
  InsertFaq(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Faq/Create`,Data);
  }
  UpdateFaq(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Faq/Update`,Data);
  }
  
  DeleteFaq(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Faq/Delete?id=${Id}`);
  }
}
