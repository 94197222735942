import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Clients} from 'src/app/shared//Models/Clients'
import Swal from 'sweetalert2';
import { ClientsService } from 'src/app/shared/API-Service/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-insert-clients',
  templateUrl: './insert-clients.component.html',
  styleUrls: ['./insert-clients.component.scss']
})
export class InsertClientsComponent {
  InsertForm: FormGroup;
  Clients=new Clients()
  maxDate: Date;
  update: boolean;
  dropdownList: any = [];
  selectedItems: any = [];
  companyTypeId: any = [];
  returnedObject: any = {};
  validated: boolean;
  Client: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];
  ClientLogoView:any[]=[];
  ClientForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveClientLogo: boolean=false;

  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private ClientsService:ClientsService) {
    this.InitForm()
   }
 
   onSelect(event) {

     this.files.push(...event.addedFiles);
     this.HaveClientLogo=false
     this.ClientLogoView.push(this.files[0])
  
     if(this.files.length > 1){
       this.replaceFile();
     }
   
   }
   
   replaceFile() {
    this.files.splice(0,1);
    this.HaveClientLogo=false
    this.ClientLogoView.splice(0.1);
   }
 
   onRemove(event) {
     this.files.splice(this.files.indexOf(event), 1);
     if(this.Client.logo){
           this.HaveClientLogo=true

     }
     this.ClientLogoView.splice(this.ClientLogoView.indexOf(event), 1);

   }
 
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.ClientsService.GetClientById(this.id).subscribe(async (res:any)=>{
    this.Client=  await res.data;
   this.Client.logo=this.imgUrlServer+this.Client.logo
   if(this.Client.logo){
    this.HaveClientLogo=true
   }
    this.InitForm(this.Client);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(Client?:Clients) {
    this.InsertForm = this._formBuilder.group({
    Title: [Client?.title, Validators.required],
    TitleAr: [Client?.titleAr, Validators.required],
    Order: [Client?.order, Validators.required],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    if (this.update == true) {
    this.UpdateClient();
    } else {
    this.InsertClient();
    }
    } else {
    this.validated = true;
    }
    }
    UpdateClient(){
      this.disableBtn=true;
      this.ClientLogoView.forEach(element => {
        this.ClientForm.set('ClientLogo', element)
      })
      this.ClientForm.set('Id',this.id)
      this.ClientForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
      this.ClientForm.set('Title',this.InsertForm.get('Title').value)
      this.ClientForm.set('Order',this.InsertForm.get('Order').value)
      this.ClientsService.UpdateClient(this.ClientForm).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم التعديل بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-client");
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
    
        );
    }
    InsertClient(){
      this.disableBtn=true;
      this.ClientLogoView.forEach(element => {
        this.ClientForm.set('ClientLogo', element)
      });
      this.ClientForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
      this.ClientForm.set('Title',this.InsertForm.get('Title').value)
      this.ClientForm.set('Order',this.InsertForm.get('Order').value)
  
      this.ClientsService.InsertClient(this.ClientForm).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-client");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
  
}
