import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Portfolio} from 'src/app/shared//Models/Portfolio'
import Swal from 'sweetalert2';
import { PortfolioService } from 'src/app/shared/API-Service/portfolio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import {ServiceSectorService} from 'src/app/shared/API-Service/service-sector.service';

@Component({
  selector: 'app-insert-portfolio',
  templateUrl: './insert-portfolio.component.html',
  styleUrls: ['./insert-portfolio.component.scss']
})
export class InsertPortfolioComponent {
  InsertForm: FormGroup;
  Portfolio=new Portfolio()
  update: boolean;
  validated: boolean;
  PortfolioData: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];
  filesGrayscale: File[] = [];

  PortfolioLogoView:any[]=[];
  PortfolioLogoGrayscaleView:any[]=[];

  PortfolioForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HavePortfolioLogo: boolean=false;
  HavePortfolioLogoGrayscale:boolean=false
  ServiceSectors: any[];
  constructor(private ServiceSectorService :ServiceSectorService, private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private PortfolioService:PortfolioService) {
    this.InitForm()
    this.GetServiceSector()
   }
   GetServiceSector(){
    this.ServiceSectorService.GetServiceSectors().subscribe(res=>{
      this.ServiceSectors=res.data
    })
   }
   
  onSelect(event) {

    this.files.push(...event.addedFiles);
    this.HavePortfolioLogo=false
    this.PortfolioLogoView.push(this.files[0])
 
    if(this.files.length > 1){
      this.replaceFile();
    }
  
  }
  
  replaceFile() {
   this.files.splice(0,1);
   this.HavePortfolioLogo=false
   this.PortfolioLogoView.splice(0.1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    if(this.PortfolioData.logo){
          this.HavePortfolioLogo=true

    }
    this.PortfolioLogoView.splice(this.PortfolioLogoView.indexOf(event), 1);

  }
  onSelectGrayscale(event) {

    this.filesGrayscale.push(...event.addedFiles);
    this.HavePortfolioLogoGrayscale=false
    this.PortfolioLogoGrayscaleView.push(this.filesGrayscale[0])
 
    if(this.filesGrayscale.length > 1){
      this.replaceFileGrayscale();
    }
  
  }
  
  replaceFileGrayscale() {
   this.filesGrayscale.splice(0,1);
   this.HavePortfolioLogoGrayscale=false
   this.PortfolioLogoGrayscaleView.splice(0.1);
  }

  onRemoveGrayscale(event) {
    this.filesGrayscale.splice(this.filesGrayscale.indexOf(event), 1);
    if(this.PortfolioData.logo){
          this.HavePortfolioLogoGrayscale=true

    }
    this.PortfolioLogoGrayscaleView.splice(this.PortfolioLogoGrayscaleView.indexOf(event), 1);

  }
 ngOnInit(): void {
   if (this.route.snapshot.paramMap.get("id")) {
  
     this.id =this.route.snapshot.paramMap.get("id")
   this.PortfolioService.GetPortfolioById(this.id).subscribe(async (res:any)=>{
   this.PortfolioData=  await res.data;
  this.PortfolioData.colorfulImage=this.imgUrlServer+this.PortfolioData.colorfulImage
  this.PortfolioData.grayScaleImage=this.imgUrlServer+this.PortfolioData.grayScaleImage

  if(this.PortfolioData.colorfulImage){
   this.HavePortfolioLogo=true
  }
  if(this.PortfolioData.grayScaleImage){
    this.HavePortfolioLogoGrayscale=true;
  }
   this.InitForm(this.PortfolioData);
   })
     this.update = true;
   } else {
     this.InitForm()
     this.update = false;
   }
 }
 InitForm(Portfolio?:Portfolio) {
   this.InsertForm = this._formBuilder.group({
    TitleAr: [Portfolio?.titleAr, Validators.required],
    Title: [Portfolio?.title, Validators.required],
    IsFeature: [Portfolio?.isFeature, Validators.required],
    ServiceSectorId: [Portfolio?.serviceSectorId, Validators.required],
    Url: [Portfolio?.url, Validators.required],
    Order: [Portfolio?.order, Validators.required],

   });
   }
   get fc() {
     return this.InsertForm.controls;
   }
 onSubmit() {
   
   if (this.InsertForm.status == "VALID") {
   this.validated = false;
   if (this.update == true) {
   this.UpdateCompanyProduct();
   } else {
   this.InsertCompanyProduct();
   }
   } else {
   this.validated = true;
   }
   }
   UpdateCompanyProduct(){
     this.disableBtn=true;
     this.PortfolioLogoView.forEach(element => {
       this.PortfolioForm.set('PortfolioColorfulImage', element)
     })
     this.PortfolioLogoGrayscaleView.forEach(element => {
      this.PortfolioForm.set('PortfolioGrayScaleImage', element)
    })
     this.PortfolioForm.set('Id',this.id)
     this.PortfolioForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.PortfolioForm.set('Title',this.InsertForm.get('Title').value)
     this.PortfolioForm.set('Url',this.InsertForm.get('Url').value)
     this.PortfolioForm.set('IsFeature',this.InsertForm.get('IsFeature').value)
     this.PortfolioForm.set('ServiceSectorId',this.InsertForm.get('ServiceSectorId').value)
     this.PortfolioForm.set('Order',this.InsertForm.get('Order').value)

     this.PortfolioService.UpdatePortfolio(this.PortfolioForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم التعديل بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-portfolio");
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
   
       );
   }
   InsertCompanyProduct(){
     this.disableBtn=true;
     this.PortfolioLogoView.forEach(element => {
      this.PortfolioForm.set('PortfolioColorfulImage', element)
    })
    this.PortfolioLogoGrayscaleView.forEach(element => {
     this.PortfolioForm.set('PortfolioGrayScaleImage', element)
   })
   this.PortfolioForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.PortfolioForm.set('Title',this.InsertForm.get('Title').value)
     this.PortfolioForm.set('Url',this.InsertForm.get('Url').value)
     this.PortfolioForm.set('IsFeature',this.InsertForm.get('IsFeature').value)
     this.PortfolioForm.set('ServiceSectorId',this.InsertForm.get('ServiceSectorId').value)
     this.PortfolioForm.set('Order',this.InsertForm.get('Order').value)
 
     this.PortfolioService.InsertPortfolio(this.PortfolioForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم الحفظ بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-portfolio");
 
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
             );
   }
}
