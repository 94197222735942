import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Numbers} from 'src/app/shared//Models/Numbers'
import Swal from 'sweetalert2';
import { NumbersService } from 'src/app/shared/API-Service/numbers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-create-update-numbers',
  templateUrl: './create-update-numbers.component.html',
  styleUrls: ['./create-update-numbers.component.scss']
})
export class CreateUpdateNumbersComponent {
  InsertForm: FormGroup;
  Numbers=new Numbers()
  maxDate: Date;
  update: boolean;
  validated: boolean;
  NumbersData: any;
  id: any;
  disableBtn:boolean
  imgUrlServer=environment.Server_URL_Image+'/'
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private NumbersService:NumbersService) {
    this.InitForm()
   }
 
 
 
  ngOnInit(): void {
    
    this.NumbersService.Get().subscribe(async (res:any)=>{
    this.NumbersData=  await res.data;
 
    this.InitForm(this.NumbersData);
    })
    
  }
  InitForm(Numbers?:Numbers) {
    this.InsertForm = this._formBuilder.group({
      Countries: [Numbers?.countries, Validators.required],
      BusinessSectors: [Numbers?.businessSectors, Validators.required],
      Services: [Numbers?.services, Validators.required],
      Clients: [Numbers?.clients, Validators.required],

    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    this.CreateOrUpdateNumbers();
    
    } else {
    this.validated = true;
    }
    }

    CreateOrUpdateNumbers(){
      this.disableBtn=true;
     
  const data= {
    "countries": this.InsertForm.get('Countries').value,
    "businessSectors": this.InsertForm.get('BusinessSectors').value,
    "services": this.InsertForm.get('Services').value,
    "clients": this.InsertForm.get('Clients').value,

  }
      this.NumbersService.CreateOrUpdate(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-faq");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
}
