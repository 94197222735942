import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Newsletter} from 'src/app/shared//Models/Newsletter'
import Swal from 'sweetalert2';
import { NewsletterService } from 'src/app/shared/API-Service/newsletter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AngularEditorValidateService } from 'src/app/shared/API-Service/angular-editor-validate.service';
@Component({
  selector: 'app-insert-newsletter',
  templateUrl: './insert-newsletter.component.html',
  styleUrls: ['./insert-newsletter.component.scss']
})
export class InsertNewsletterComponent {
  InsertForm: FormGroup;
  Newsletter=new Newsletter()
  maxDate: Date;
  update: boolean;
  validated: boolean;
  NewsletterData: any;
  id: any;
  disableBtn:boolean
  imgUrlServer=environment.Server_URL_Image+'/'
  // config = {
  //   toolbar: [
  //     'undo',
  //     'redo',
  //     '|',
  //     'heading',
  //     'fontFamily',
  //     'fontSize',
  //     '|',
  //     'bold',
  //     'italic',
  //     'underline',
  //     'fontColor',
  //     'fontBackgroundColor',
  //     'highlight',
  //     '|',
  //     'link',
  //     'CKFinder',
  //     'imageUpload',
  //     'mediaEmbed',
  //     '|',
  //     'alignment',
  //     'bulletedList',
  //     'numberedList',
  //     '|',
  //     'indent',
  //     'outdent',
  //     '|',
  //     'insertTable',
  //     'blockQuote',
  //     'specialCharacters'
  //   ],
  //   language: 'id',
  //   image: {
  //     toolbar: [
  //       'imageTextAlternative',
  //       'imageStyle:full',
  //       'imageStyle:side'
  //     ]
  //   },
  // }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      // ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor( private AngularEditorValidateService:AngularEditorValidateService,private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private NewsletterService:NewsletterService) {
    this.InitForm()
   }
 
 
 
  ngOnInit(): void {
    

  }
  InitForm(Newsletter?:Newsletter) {
    this.InsertForm = this._formBuilder.group({
      Subject: [Newsletter?.subject, Validators.required],
      Body: [Newsletter?.body, Validators.required],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    if(this.AngularEditorValidateService.AngularEditorValidate(this.InsertForm.get('Body'))){
      this.InsertForm.get('Body').setErrors({AngularEditorError:true})
    }
 
  
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    this.SendMailToAllSubscriptions();
    
    } else {
    this.validated = true;
    }
    }

    SendMailToAllSubscriptions(){
      this.disableBtn=true;
     
  const data= {
    "subject": this.InsertForm.get('Subject').value,
    "body": this.InsertForm.get('Body').value,
    "isHtmlBody": true,
  }
      this.NewsletterService.SendMailToAllSubscriptions(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الارسال بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-news-letter");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
}
