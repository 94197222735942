import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import {ClientsService} from 'src/app/shared/API-Service/clients.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss']
})
export class ListClientsComponent extends BaseComponent implements OnInit {
  Clients: any;

  constructor(private router: Router,private ClientsService:ClientsService) { super()
  
    
  }

  ngOnInit(): void {
  this.GetClientsList();
  }

  AddNew(){
    this.router.navigateByUrl("content/admin/insert-client");
  }
  pageChanged(event:any){
    this.pageNumber = event;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
this.GetClientsList();
   
  }
  update(id){
    this.router.navigate([`content/admin/update-client`, id]);
  }
  GetClientsList(){
    this.ClientsService.GetClientsPaged(this.pager).subscribe(
      (res:any) => {
        this.Clients=res.data.items;
        this.totalCount = res.data.totalCount;
        
      },
      err => {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: err.error,
        // })
      }
    )
  }
  Delete(id: number) {
    Swal.fire({
      title: ' تحذير !',
      text: "هل انت متأكد من حذف هذا العنصر ؟ ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7364f6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حذف',
      cancelButtonText: 'إنهاء',
    })
      .then((result) => {

        if (result.isConfirmed) {
          
          this.ClientsService.DeleteClient(id).subscribe(
            response => {
              this.GetClientsList();
              Swal.fire({
                icon: 'success',
                title: "تم الحذف بنجاح",
                showConfirmButton: false,
                timer: 1500
              })
            },
            err => {
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: err.error.message,
              })
            }
          )

        } else {
          // Swal.fire(
          //   'Your appointment still active ',
          // );
        }
      });
  }
}
