import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { InsertClientsComponent } from './Clients/insert-clients/insert-clients.component';
import { ListClientsComponent } from './Clients/list-clients/list-clients.component';
import { SharedModule } from 'src/app/shared/shared.module'
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { InsertCareerComponent } from './Career/insert-career/insert-career.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListCareerRequestComponent } from './CareerRequest/list-career-request/list-career-request.component';
import { InsertCareerRequestComponent } from './CareerRequest/insert-career-request/insert-career-request.component';
import { ListCompanyProductComponent } from './CompanyProduct/list-company-product/list-company-product.component';
import { InsertCompanyProductComponent } from './CompanyProduct/insert-company-product/insert-company-product.component';
import { ListPortfolioComponent } from './Portfolio/list-portfolio/list-portfolio.component';
import { InsertPortfolioComponent } from './Portfolio/insert-portfolio/insert-portfolio.component';
import { ListServiceComponent } from './Service/list-service/list-service.component';
import { InsertServiceComponent } from './Service/insert-service/insert-service.component';
import { ListServiceSectorComponent } from './ServiceSector/list-service-sector/list-service-sector.component';
import { InsertServiceSectorComponent } from './ServiceSector/insert-service-sector/insert-service-sector.component';
import { ListTestimonialComponent } from './Testimonial/list-testimonial/list-testimonial.component';
import { InsertTestimonialComponent } from './Testimonial/insert-testimonial/insert-testimonial.component';
import { ListTickerComponent } from './Ticker/list-ticker/list-ticker.component';
import { InsertTickerComponent } from './Ticker/insert-ticker/insert-ticker.component';
import { ListFaqComponent } from './Faq/list-faq/list-faq.component';
import { InsertFaqComponent } from './Faq/insert-faq/insert-Faq.component';
import { CreateUpdateNumbersComponent } from './Numbers/create-update-numbers/create-update-numbers.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ListContactInquiryComponent } from './ContactInquiry/list-contact-inquiry/list-contact-inquiry.component';
import { InsertContactInquiryComponent } from './ContactInquiry/insert-contact-inquiry/insert-contact-inquiry.component';
import { ListNewsletterComponent } from './Newsletter/list-newsletter/list-newsletter.component';
import { InsertNewsletterComponent } from './Newsletter/insert-newsletter/insert-newsletter.component';
import { ListUsersComponent } from './ْUsers/list-users/list-users.component';
import { InsertUserComponent } from './ْUsers/insert-user/insert-user.component';

@NgModule({
  declarations: [InsertClientsComponent, ListClientsComponent,
    InsertCareerComponent, ListCareerRequestComponent,
    InsertCareerRequestComponent, ListCompanyProductComponent,
    InsertCompanyProductComponent, ListPortfolioComponent, InsertPortfolioComponent,
    ListServiceComponent, InsertServiceComponent, ListServiceSectorComponent, InsertServiceSectorComponent
    , ListTestimonialComponent, InsertTestimonialComponent, ListTickerComponent, InsertTickerComponent,
    ListFaqComponent, InsertFaqComponent, CreateUpdateNumbersComponent, ListContactInquiryComponent, InsertContactInquiryComponent, ListNewsletterComponent, InsertNewsletterComponent, ListUsersComponent, InsertUserComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    NgxPaginationModule,
    NgxDropzoneModule,
    NgSelectModule,
    AngularEditorModule,
  ]
})
export class AdminModule { }
