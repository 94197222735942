import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListClientsComponent } from './Clients/list-clients/list-clients.component';
import { InsertClientsComponent } from './Clients/insert-clients/insert-clients.component';
import { ListCareerComponent } from './Career/list-career/list-career.component';
import { InsertCareerComponent } from './Career/insert-career/insert-career.component';
import { ListCareerRequestComponent } from './CareerRequest/list-career-request/list-career-request.component';
import { InsertCareerRequestComponent } from './CareerRequest/insert-career-request/insert-career-request.component';
import { ListCompanyProductComponent } from './CompanyProduct/list-company-product/list-company-product.component';
import { InsertCompanyProductComponent } from './CompanyProduct/insert-company-product/insert-company-product.component';
import { IsLoginGuard } from 'src/app/shared/services/is-login.guard';
import { InsertPortfolioComponent } from './Portfolio/insert-portfolio/insert-portfolio.component';
import { ListPortfolioComponent } from './Portfolio/list-portfolio/list-portfolio.component';
import { ListServiceComponent } from './Service/list-service/list-service.component';
import { InsertServiceComponent } from './Service/insert-service/insert-service.component';
import { ListServiceSectorComponent } from './ServiceSector/list-service-sector/list-service-sector.component';
import { InsertServiceSectorComponent } from './ServiceSector/insert-service-sector/insert-service-sector.component';
import { ListTestimonialComponent } from './Testimonial/list-testimonial/list-testimonial.component';
import { InsertTestimonialComponent } from './Testimonial/insert-testimonial/insert-testimonial.component';
import { ListTickerComponent } from './Ticker/list-ticker/list-ticker.component';
import { InsertTickerComponent } from './Ticker/insert-ticker/insert-ticker.component';
import {  ListFaqComponent } from './Faq/list-faq/list-faq.component';
import { InsertFaqComponent } from './Faq/insert-faq/insert-Faq.component';
import { CreateUpdateNumbersComponent } from './Numbers/create-update-numbers/create-update-numbers.component';
import { ListNewsletterComponent } from './Newsletter/list-newsletter/list-newsletter.component';
import { InsertNewsletterComponent } from './Newsletter/insert-newsletter/insert-newsletter.component';
import { ListContactInquiryComponent } from './ContactInquiry/list-contact-inquiry/list-contact-inquiry.component';
import { InsertContactInquiryComponent } from './ContactInquiry/insert-contact-inquiry/insert-contact-inquiry.component';
import { ListUsersComponent } from './ْUsers/list-users/list-users.component';
import { InsertUserComponent } from './ْUsers/insert-user/insert-user.component';
import { ChangePasswordComponent } from 'src/app/auth/change-password/change-password.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: 'change-password',
        canActivate: [IsLoginGuard],
        component:ChangePasswordComponent
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-users",
        component: ListUsersComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-user",
        component: InsertUserComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-user/:id",
        component: InsertUserComponent,
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-client",
        component: ListClientsComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-client",
        component: InsertClientsComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-client/:id",
        component: InsertClientsComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-career",
        component: ListCareerComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-career",
        component: InsertCareerComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-career/:id",
        component: InsertCareerComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-Career-request",
        component: ListCareerRequestComponent,
    
      },
      // {
      //   canActivate: [IsLoginGuard],
      //   path: "insert-Career-request",
      //   component: InsertCareerRequestComponent,
    
      // },
      {
        canActivate: [IsLoginGuard],
        path: "update-Career-request/:id",
        component: InsertCareerRequestComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-company-product",
        component: ListCompanyProductComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-company-product",
        component: InsertCompanyProductComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-company-product/:id",
        component: InsertCompanyProductComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-portfolio",
        component: ListPortfolioComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-portfolio",
        component: InsertPortfolioComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-portfolio/:id",
        component: InsertPortfolioComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-service",
        component: ListServiceComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-service",
        component: InsertServiceComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-service/:id",
        component: InsertServiceComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-service-sector",
        component: ListServiceSectorComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-service-sector",
        component: InsertServiceSectorComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-service-sector/:id",
        component: InsertServiceSectorComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-testimonial",
        component: ListTestimonialComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-testimonial",
        component: InsertTestimonialComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-testimonial/:id",
        component: InsertTestimonialComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-ticker",
        component: ListTickerComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-ticker",
        component: InsertTickerComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-ticker/:id",
        component: InsertTickerComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-faq",
        component: ListFaqComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-faq",
        component: InsertFaqComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-faq/:id",
        component: InsertFaqComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-news-letter",
        component: ListNewsletterComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-news-letter",
        component: InsertNewsletterComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-news-letter/:id",
        component: InsertNewsletterComponent,
      },
      {
        canActivate: [IsLoginGuard],
        path: "list-contact-inquiry",
        component: ListContactInquiryComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "insert-contact-inquiry",
        component: InsertContactInquiryComponent,
    
      },
      {
        canActivate: [IsLoginGuard],
        path: "update-contact-inquiry/:id",
        component: InsertContactInquiryComponent,
      },
        {
        canActivate: [IsLoginGuard],
        path: "numbers",
        component: CreateUpdateNumbersComponent,
    
      },
      
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
