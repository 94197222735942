import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class NumbersService {
  constructor(private http:HttpClient,private router:Router) { }
 
  CreateOrUpdate(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Numbers/CreateOrUpdate`,data);
  }
  Get(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Numbers/Get`);
  }
}
