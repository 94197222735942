<app-breadcrumb [title]="'إدارة الخدمات'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <div *ngIf="update; else elseBlock1">
            <h5 class="pb-2">تعديل خدمة موجودة</h5>
            يمكنك تعديل خدمة أو أكثر فى الخدمات
          </div>
          <ng-template #elseBlock1>
            <h5 class="pb-2">إضافة خدمة جديدة</h5>
            يمكنك إضافة خدمة أو أكثر فى الخدمات
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
            [ngClass]="{'was-validated': validated}">

            <div class="row">
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم باللغة العربية</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="TitleAr" />
              </div>


              <div class="col-6 mb-3">

                <label for="validationCustom01"> الاسم باللغة الانجليزية</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="Title" />
              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الوصف باللغة العربية</label>
                <angular-editor formControlName="DescriptionAr" [config]="config" required=""></angular-editor>
              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الوصف باللغة الانجليزية</label>
                <angular-editor formControlName="Description" [config]="config" required=""></angular-editor>
              </div>
              <div class="col-6 mb-3">

                <label for="validationCustom01"> رابط الفيديو</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="VideoUrl" />
              </div>

              <div class="col-6 mb-3">
                <label for="validationCustom01"> قطاع الخدمات </label>
                <ng-select [items]="ServiceSectors" placeholder="Select" class="js-example-placeholder-multiple"
                  bindLabel="title" bindValue="id" formControlName="ServiceSectorId"></ng-select>
              </div>

              <div class="col-6 mb-3">
                <label for="validationCustom01"> الترتيب</label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="Order" />
              </div>

              <div class="col-6 mb-3">
                <label for="validationCustom01"> صورة الخدمة</label>
                <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="icon-cloud-up"></i>
                      <h6>Drop image here or click to upload.</h6>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                    [removable]="true" (removed)="onRemove(f)">
                  </ngx-dropzone-image-preview>
                  <img class="imgfromUrl" *ngIf="HaveServiceImage" [src]="ServiceData.image" alt="Image">
                </ngx-dropzone>
              </div>
            </div>



            <div *ngIf="update; else elseBlock">
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                تعديل
              </button>
            </div>
            <ng-template #elseBlock>
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                إضافة
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>