import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class ContactInquiryService {
  constructor(private http:HttpClient,private router:Router) { }
  GetContactInquiry(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ContactInquiry/Get`);
  }
  GetContactInquiryPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/ContactInquiry/GetPaged`,data);
  }
  GetContactInquiryById(ContactInquiryId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ContactInquiry/GetById?Id=${ContactInquiryId}`);
  }
  
  // InsertContactInquiry(Data): Observable<GenericResponseSingle<any>> {
  //   return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/ContactInquiry/Create`,Data);
  // }
  // UpdateContactInquiry(Data): Observable<GenericResponseSingle<any>> {
  //   return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/ContactInquiry/Update`,Data);
  // }
  MarkAsViewed(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ContactInquiry/MarkAsViewed?id=${Id}`);
  }
  DeleteContactInquiry(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ContactInquiry/Delete?id=${Id}`);
  }
}
