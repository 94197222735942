import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import {UsersService} from 'src/app/shared/API-Service/users.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent  extends BaseComponent implements OnInit{
  Users: any;

  constructor(private router: Router,private UsersService:UsersService) { super()
  
    
  }

  ngOnInit(): void {
  this.GetUsersList();
  }

  AddNew(){
    this.router.navigateByUrl("content/admin/insert-user");
  }
  pageChanged(event:any){
    this.pageNumber = event;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
this.GetUsersList();
   
  }
  update(id){
    this.router.navigate([`content/admin/update-user`, id]);
  }
  GetUsersList(){
    this.UsersService.GetAllUsers().subscribe(
      (res:any) => {
        this.Users=res.data;        
      },
      err => {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: err.error,
        // })
      }
    )
  }


 // GetUsersList(){
  //   this.UsersService.GetUserPaged(this.pager).subscribe(
  //     (res:any) => {
  //       this.Users=res.data.items;
  //       this.totalCount = res.data.totalCount;
        
  //     },
  //     err => {
  //       // Swal.fire({
  //       //   icon: 'error',
  //       //   title: 'خطأ',
  //       //   text: err.error,
  //       // })
  //     }
  //   )
  // }
  // Delete(id: number) {
  //   Swal.fire({
  //     title: ' تحذير !',
  //     text: "هل انت متأكد من حذف هذا العنصر ؟ ",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#7364f6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'حذف',
  //     cancelButtonText: 'إنهاء',
  //   })
  //     .then((result) => {

  //       if (result.isConfirmed) {
          
  //         this.UsersService.(id).subscribe(
  //           response => {
  //             this.GetTickerList();
  //             Swal.fire({
  //               icon: 'success',
  //               title: "تم الحذف بنجاح",
  //               showConfirmButton: false,
  //               timer: 1500
  //             })
  //           },
  //           err => {
  //             Swal.fire({
  //               icon: 'error',
  //               title: 'خطأ',
  //               text: err.error.message,
  //             })
  //           }
  //         )

  //       } else {
  //         // Swal.fire(
  //         //   'Your appointment still active ',
  //         // );
  //       }
  //     });
  // }



  
  // Delete(id: number) {
  //   Swal.fire({
  //     title: ' تحذير !',
  //     text: "هل انت متأكد من حذف هذا العنصر ؟ ",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#7364f6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'حذف',
  //     cancelButtonText: 'إنهاء',
  //   })
  //     .then((result) => {

  //       if (result.isConfirmed) {
          
  //         this.UsersService.(id).subscribe(
  //           response => {
  //             this.GetTickerList();
  //             Swal.fire({
  //               icon: 'success',
  //               title: "تم الحذف بنجاح",
  //               showConfirmButton: false,
  //               timer: 1500
  //             })
  //           },
  //           err => {
  //             Swal.fire({
  //               icon: 'error',
  //               title: 'خطأ',
  //               text: err.error.message,
  //             })
  //           }
  //         )

  //       } else {
  //         // Swal.fire(
  //         //   'Your appointment still active ',
  //         // );
  //       }
  //     });
  // }
 
}
