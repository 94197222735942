import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Users} from 'src/app/shared//Models/Users'
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/shared/API-Service/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-insert-user',
  templateUrl: './insert-user.component.html',
  styleUrls: ['./insert-user.component.scss']
})
export class InsertUserComponent {
  InsertForm: FormGroup;
  Users=new Users()
  maxDate: Date;
  update: boolean;
  validated: boolean;
  UserData: any;
  id: any;
  disableBtn:boolean
  imgUrlServer=environment.Server_URL_Image+'/'
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private UsersService:UsersService) {
    this.InitForm()
   }
 
 
 
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.UsersService.GetUserById(this.id).subscribe(async (res:any)=>{
    this.UserData=  await res.data;
 
    this.InitForm(this.UserData);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(Users?:Users) {
    this.InsertForm = this._formBuilder.group({
    Name: [Users?.name, Validators.required],
    UserName: [Users?.userName, Validators.required],
    PasswordHash: ['', Validators.required],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    // if (this.update == true) {
    // this.UpdateUser();
    // } 
    // else {
    this.InsertUser();
    // }
    } 
    else {
    this.validated = true;
    }
    }
    // UpdateUser(){
    //   this.disableBtn=true;
    //   const data= {
    //     "id":this.id,
    //     "title": this.InsertForm.get('Title').value,
    //     "titleAr": this.InsertForm.get('TitleAr').value,
    //     "order": this.InsertForm.get('Order').value,
    //   }
    //   this.UsersService.(data).subscribe(
    //     (response) => {
    //     Swal.fire({
    //     icon: "success",
    //     title: "تم التعديل بنجاح",
    //     showConfirmButton: false,
    //     timer: 1500,
    //     });
    //     this.disableBtn=false;
    //     this.router.navigateByUrl("content/admin/list-User");
    //   },
    //     (err) => {
    //       this.disableBtn=false;

    //       Swal.fire({
    //         icon: "error",
    //         title:err.error.message
    //         });
    //     }
    
    //     );
    // }
    InsertUser(){
      this.disableBtn=true;
     
  const data= {
    "name": this.InsertForm.get('Name').value,
    "userName": this.InsertForm.get('UserName').value,
    "passwordHash": this.InsertForm.get('PasswordHash').value,
  }
      this.UsersService.InsertUser(data).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-User");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
}
