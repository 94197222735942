import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "القايمة الرئيسية",
    },
    {
      title: "المستخدمين",
      icon: "user",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      // active: true,
      children: [
        { path: "/content/admin/list-users", title: "المستخدمين", type: "link" },
        { path: "/content/admin/insert-user", title: "اضافة مستخدم", type: "link" },
      ],
    },
    {
      title: "العملاء",
      icon: "user",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-client", title: "العملاء", type: "link" },
        { path: "/content/admin/insert-client", title: "اضافة عميل", type: "link" },
      ],
    },
    {
      title: "الوظائف",
      icon: "briefcase",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-career", title: "الوظائف", type: "link" },
        { path: "/content/admin/insert-career", title: "اضافة وظيفة", type: "link" },
      ],
    },
  
    {
      title: "طلبات التوظيف",
      icon: "book-open",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "1",
      children: [
        { path: "/content/admin/list-Career-request", title: "طلبات التوظيف", type: "link" },
        // { path: "/content/admin/insert-Career-request", title: "اضافة طلب توظيف", type: "link" },
      ],
    },
    {
      title: "منتجات الشركة",
      icon: "shopping-bag",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-company-product", title: "منتجات الشركة", type: "link" },
        { path: "/content/admin/insert-company-product", title: "اضافة منتج للشركة", type: "link" },
      ],
    },
    {
      title: "نماذج الاعمال",
      icon: "airplay",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-portfolio", title: "نماذج الاعمال", type: "link" },
        { path: "/content/admin/insert-portfolio", title: "اضافة نموذج", type: "link" },
      ],
    },
    {
      title: "الخدمات",
      icon: "activity",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-service", title: "الخدمات", type: "link" },
        { path: "/content/admin/insert-service", title: "اضافة خدمة", type: "link" },
      ],
    },
    {
      title: "قطاع الخدمات",
      icon: "activity",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-service-sector", title: "قطاع الخدمات", type: "link" },
        { path: "/content/admin/insert-service-sector", title: "اضافة قطاع خدمة", type: "link" },
      ],
    },
    {
      title: "الاراء",
      icon: "message-circle",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-testimonial", title: "الاراء", type: "link" },
        { path: "/content/admin/insert-testimonial", title: "اضافة رأي", type: "link" },
      ],
    },
    {
      title: "المؤشرات",
      icon: "award",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-ticker", title: "المؤشرات", type: "link" },
        { path: "/content/admin/insert-ticker", title: "اضافة مؤشر", type: "link" },
      ],
    },
    {
      title: "التعليمات",
      icon: "bell",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-faq", title: "التعليمات", type: "link" },
        { path: "/content/admin/insert-faq", title: "اضافة تعليمات", type: "link" },
      ],
    },
    {
      title: "الاخبار",
      icon: "file-text",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "2",
      children: [
        { path: "/content/admin/list-news-letter", title: "الاخبار", type: "link" },
        { path: "/content/admin/insert-news-letter", title: "ارسال الخبر للمشتركين", type: "link" },
      ],
    },
    {
      title: "الاستفسارات",
      icon: "info",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "1",
      children: [
        { path: "/content/admin/list-contact-inquiry", title: "الاستفسارات", type: "link" },
        // { path: "/content/admin/insert-contact-inquiry", title: "اضافة استفسار", type: "link" },
      ],
    },
    {
      title: "الارقام",
      icon: "sliders",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "1",
      children: [
        { path: "/content/admin/numbers", title: "الارقام", type: "link" },
      ],
    },
  ];
  
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
