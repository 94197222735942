import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Testimonial} from 'src/app/shared//Models/Testimonial'
import Swal from 'sweetalert2';
import { TestimonialService } from 'src/app/shared/API-Service/testimonial.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-insert-testimonial',
  templateUrl: './insert-testimonial.component.html',
  styleUrls: ['./insert-testimonial.component.scss']
})
export class InsertTestimonialComponent {
  InsertForm: FormGroup;
  Testimonial=new Testimonial()
  update: boolean;
  validated: boolean;
  TestimonialData: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];

  TestimonialImageView:any[]=[];

  TestimonialForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveTestimonialImage: boolean=false;
  constructor( private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private TestimonialService:TestimonialService) {
    this.InitForm()
   }

   
  onSelect(event) {

    this.files.push(...event.addedFiles);
    this.HaveTestimonialImage=false
    this.TestimonialImageView.push(this.files[0])
 
    if(this.files.length > 1){
      this.replaceFile();
    }
  
  }
  
  replaceFile() {
   this.files.splice(0,1);
   this.HaveTestimonialImage=false
   this.TestimonialImageView.splice(0.1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    if(this.TestimonialData.logo){
          this.HaveTestimonialImage=true

    }
    this.TestimonialImageView.splice(this.TestimonialImageView.indexOf(event), 1);

  }
  
  
 ngOnInit(): void {
   if (this.route.snapshot.paramMap.get("id")) {
  
     this.id =this.route.snapshot.paramMap.get("id")
   this.TestimonialService.GetTestimonialById(this.id).subscribe(async (res:any)=>{
   this.TestimonialData=  await res.data;
  this.TestimonialData.image=this.imgUrlServer+this.TestimonialData.image

  if(this.TestimonialData.image){
   this.HaveTestimonialImage=true
  }
  
   this.InitForm(this.TestimonialData);
   })
     this.update = true;
   } else {
     this.InitForm()
     this.update = false;
   }
 }
 InitForm(Testimonial?:Testimonial) {
   this.InsertForm = this._formBuilder.group({
    Name: [Testimonial?.name, Validators.required],
    NameAr: [Testimonial?.nameAr, Validators.required],
    JobTitle: [Testimonial?.jobTitle, Validators.required],
    JobTitleAr: [Testimonial?.jobTitleAr, Validators.required],
    Order: [Testimonial?.order, Validators.required],
    TestimonialTextAr: [Testimonial?.testimonialTextAr, Validators.required],
    TestimonialText: [Testimonial?.testimonialText, Validators.required],

   });
   }
   get fc() {
     return this.InsertForm.controls;
   }
 onSubmit() {
   
   if (this.InsertForm.status == "VALID") {
   this.validated = false;
   if (this.update == true) {
   this.UpdateCompanyProduct();
   } else {
   this.InsertCompanyProduct();
   }
   } else {
   this.validated = true;
   }
   }
   UpdateCompanyProduct(){
     this.disableBtn=true;
     this.TestimonialImageView.forEach(element => {
      this.TestimonialForm.set('TestimonialImage', element)
    })
     
    this.TestimonialForm.set('Id',this.id)
   this.TestimonialForm.set('NameAr',this.InsertForm.get('NameAr').value)
     this.TestimonialForm.set('Name',this.InsertForm.get('Name').value)
     this.TestimonialForm.set('JobTitleAr',this.InsertForm.get('JobTitleAr').value)
     this.TestimonialForm.set('JobTitle',this.InsertForm.get('JobTitle').value)
     this.TestimonialForm.set('TestimonialTextAr',this.InsertForm.get('TestimonialTextAr').value)
     this.TestimonialForm.set('TestimonialText',this.InsertForm.get('TestimonialText').value)
     this.TestimonialForm.set('Order',this.InsertForm.get('Order').value)
     this.TestimonialService.UpdateTestimonial(this.TestimonialForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم التعديل بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-testimonial");
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
   
       );
   }
   InsertCompanyProduct(){
     this.disableBtn=true;
     this.TestimonialImageView.forEach(element => {
      this.TestimonialForm.set('TestimonialImage', element)
    })
 
    this.TestimonialForm.set('NameAr',this.InsertForm.get('NameAr').value)
    this.TestimonialForm.set('Name',this.InsertForm.get('Name').value)
    this.TestimonialForm.set('JobTitleAr',this.InsertForm.get('JobTitleAr').value)
    this.TestimonialForm.set('JobTitle',this.InsertForm.get('JobTitle').value)
    this.TestimonialForm.set('TestimonialTextAr',this.InsertForm.get('TestimonialTextAr').value)
    this.TestimonialForm.set('TestimonialText',this.InsertForm.get('TestimonialText').value)
    this.TestimonialForm.set('Order',this.InsertForm.get('Order').value)
 
     this.TestimonialService.InsertTestimonial(this.TestimonialForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم الحفظ بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-testimonial");
 
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
             );
   }
}
