import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import {LoginService } from 'src/app/shared/API-Service/login.service'

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private fb: FormBuilder, public router: Router,private LoginService :LoginService) {
    this.loginForm = this.fb.group({
      userName: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  login() {
    this.LoginService.user_login(this.loginForm.value).subscribe((res) => {
      
      console.log(res);
      Swal.fire({
        icon: "success",
        title: "Signed in successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      localStorage.setItem('Authorization',res.data["token"]);
      localStorage.setItem('username',res.data["userName"]);
      localStorage.setItem("UserData",JSON.stringify(res.data));
      console.log(res.data)

      this.router.navigate(["/content/admin"]);
},(err) =>{
console.log("their is an error");
Swal.fire({
  icon: 'error',
  title: 'failed to sign in',
  text:err.error.message    
})
}, () =>{
  console.log("completed");
  
});
    // if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
    //   let user = {
    //     email: "Test@gmail.com",
    //     password: "test123",
    //     name: "test user",
    //   };
    //   localStorage.setItem("user", JSON.stringify(user));
    //   localStorage.setItem('Authorization',res.data["token"]);
    //   this.router.navigate(["/content/admin"]);
    // }
  }

  showPassword(){
    this.show = !this.show
  }
}
