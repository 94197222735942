import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CompanyProduct} from 'src/app/shared//Models/CompanyProduct'
import Swal from 'sweetalert2';
import { CompanyProductService } from 'src/app/shared/API-Service/company-product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularEditorValidateService } from 'src/app/shared/API-Service/angular-editor-validate.service';

@Component({
  selector: 'app-insert-company-product',
  templateUrl: './insert-company-product.component.html',
  styleUrls: ['./insert-company-product.component.scss']
})
export class InsertCompanyProductComponent {

  InsertForm: FormGroup;
  CompanyProduct=new CompanyProduct()
  update: boolean;
  validated: boolean;
  CompanyProductData: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];
  CompanyProductLogoView:any[]=[];
  CompanyProductForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveCompanyProductLogo: boolean=false;
  // config = {
  //   toolbar: [
  //     'undo',
  //     'redo',
  //     '|',
  //     'heading',
  //     'fontFamily',
  //     'fontSize',
  //     '|',
  //     'bold',
  //     'italic',
  //     'underline',
  //     'fontColor',
  //     'fontBackgroundColor',
  //     'highlight',
  //     '|',
  //     'link',
  //     'CKFinder',
  //     'imageUpload',
  //     'mediaEmbed',
  //     '|',
  //     'alignment',
  //     'bulletedList',
  //     'numberedList',
  //     '|',
  //     'indent',
  //     'outdent',
  //     '|',
  //     'insertTable',
  //     'blockQuote',
  //     'specialCharacters'
  //   ],
  //   language: 'id',
  //   image: {
  //     toolbar: [
  //       'imageTextAlternative',
  //       'imageStyle:full',
  //       'imageStyle:side'
  //     ]
  //   },
  // }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      // ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor(private AngularEditorValidateService:AngularEditorValidateService, private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private CompanyProductService:CompanyProductService) {
    this.InitForm()
   }
  onSelect(event) {
   

    this.files.push(...event.addedFiles);
    this.HaveCompanyProductLogo=false
    this.CompanyProductLogoView.push(this.files[0])
 
    if(this.files.length > 1){
      this.replaceFile();
    }
  
  }
  
  replaceFile() {
   this.files.splice(0,1);
   this.HaveCompanyProductLogo=false
   this.CompanyProductLogoView.splice(0.1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    if(this.CompanyProductData.logo){
          this.HaveCompanyProductLogo=true

    }
    this.CompanyProductLogoView.splice(this.CompanyProductLogoView.indexOf(event), 1);

  }

 ngOnInit(): void {
   if (this.route.snapshot.paramMap.get("id")) {
  
     this.id =this.route.snapshot.paramMap.get("id")
   this.CompanyProductService.GetCompanyProductById(this.id).subscribe(async (res:any)=>{
   this.CompanyProductData=  await res.data;
  this.CompanyProductData.logo=this.imgUrlServer+this.CompanyProductData.logo
  if(this.CompanyProductData.logo){
   this.HaveCompanyProductLogo=true
  }
   this.InitForm(this.CompanyProductData);
   })
     this.update = true;
   } else {
     this.InitForm()
     this.update = false;
   }
 }
 InitForm(CompanyProduct?:CompanyProduct) {
   this.InsertForm = this._formBuilder.group({
    TitleAr: [CompanyProduct?.titleAr, Validators.required],
    Title: [CompanyProduct?.title, Validators.required],
    DescriptionAr: [CompanyProduct?.descriptionAr, Validators.required],
    Description: [CompanyProduct?.description, Validators.required],
    Year: [CompanyProduct?.year, Validators.required],

   });
   }
   get fc() {
     return this.InsertForm.controls;
   }
 onSubmit() {
  if(this.AngularEditorValidateService.AngularEditorValidate(this.InsertForm.get('Description'))){
    this.InsertForm.get('Description').setErrors({AngularEditorError:true})
  }
  if(this.AngularEditorValidateService.AngularEditorValidate(this.InsertForm.get('DescriptionAr'))){
    this.InsertForm.get('DescriptionAr').setErrors({AngularEditorError:true})
  }

if (this.InsertForm.status == "VALID") {
   this.validated = false;
   if (this.update == true) {
   this.UpdateCompanyProduct();
   } else {
   this.InsertCompanyProduct();
   }
   } else {
   this.validated = true;
   }
   }
   UpdateCompanyProduct(){
     this.disableBtn=true;
     this.CompanyProductLogoView.forEach(element => {
       this.CompanyProductForm.set('CompanyProductLogo', element)
     })
     this.CompanyProductForm.set('Id',this.id)
     this.CompanyProductForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.CompanyProductForm.set('Title',this.InsertForm.get('TitleAr').value)
     this.CompanyProductForm.set('DescriptionAr',this.InsertForm.get('DescriptionAr').value)
     this.CompanyProductForm.set('Description',this.InsertForm.get('Description').value)
     this.CompanyProductForm.set('Year',this.InsertForm.get('Year').value)
     this.CompanyProductService.UpdateCompanyProduct(this.CompanyProductForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم التعديل بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-company-product");
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
   
       );
   }
   InsertCompanyProduct(){
     this.disableBtn=true;
     this.CompanyProductLogoView.forEach(element => {
       this.CompanyProductForm.set('CompanyProductLogo', element)
     });
     this.CompanyProductForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.CompanyProductForm.set('Title',this.InsertForm.get('TitleAr').value)
     this.CompanyProductForm.set('DescriptionAr',this.InsertForm.get('DescriptionAr').value)
     this.CompanyProductForm.set('Description',this.InsertForm.get('Description').value)
     this.CompanyProductForm.set('Year',this.InsertForm.get('Year').value)
 
     this.CompanyProductService.InsertCompanyProduct(this.CompanyProductForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم الحفظ بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-company-product");
 
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
             );
   }
}
