import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TickerService {
  constructor(private http:HttpClient,private router:Router) { }
 
  GetTicker(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Ticker/GetDB`);
  }
  GetTickerPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/Ticker/GetPagedDB`,data);
  }
  GetTickerById(TickerId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Ticker/GetByIdDB?Id=${TickerId}`);
  }
  InsertTicker(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Ticker/Create`,Data);
  }
  UpdateTicker(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/Ticker/Update`,Data);
  }
  
  DeleteTicker(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Ticker/Delete?id=${Id}`);
  }
}
