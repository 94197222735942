import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CompanyProductService {
  constructor(private http:HttpClient,private router:Router) { }
  GetCompanyProduct(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/CompanyProduct/GetDB`);
  }
  GetCompanyProductPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/CompanyProduct/GetPagedDB`,data);
  }
  GetCompanyProductById(CompanyProductId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/CompanyProduct/GetByIdDB?Id=${CompanyProductId}`);
  }
  InsertCompanyProduct(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/CompanyProduct/Create`,Data);
  }
  UpdateCompanyProduct(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/CompanyProduct/Update`,Data);
  }
  
  DeleteCompanyProduct(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/CompanyProduct/Delete?id=${Id}`);
  }
}
