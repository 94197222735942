import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Service} from 'src/app/shared//Models/Service'
import Swal from 'sweetalert2';
import { ServiceService } from 'src/app/shared/API-Service/service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import {ServiceSectorService} from 'src/app/shared/API-Service/service-sector.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AngularEditorValidateService } from 'src/app/shared/API-Service/angular-editor-validate.service';

@Component({
  selector: 'app-insert-service',
  templateUrl: './insert-service.component.html',
  styleUrls: ['./insert-service.component.scss']
})
export class InsertServiceComponent {
  InsertForm: FormGroup;
  Service=new Service()
  update: boolean;
  validated: boolean;
  ServiceData: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];

  ServiceImageView:any[]=[];

  ServiceForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveServiceImage: boolean=false;
  ServiceSectors: any[];
  // config = {
  //   toolbar: [
  //     'undo',
  //     'redo',
  //     '|',
  //     'heading',
  //     'fontFamily',
  //     'fontSize',
  //     '|',
  //     'bold',
  //     'italic',
  //     'underline',
  //     'fontColor',
  //     'fontBackgroundColor',
  //     'highlight',
  //     '|',
  //     'link',
  //     'CKFinder',
  //     'imageUpload',
  //     'mediaEmbed',
  //     '|',
  //     'alignment',
  //     'bulletedList',
  //     'numberedList',
  //     '|',
  //     'indent',
  //     'outdent',
  //     '|',
  //     'insertTable',
  //     'blockQuote',
  //     'specialCharacters'
  //   ],
  //   language: 'id',
  //   image: {
  //     toolbar: [
  //       'imageTextAlternative',
  //       'imageStyle:full',
  //       'imageStyle:side'
  //     ]
  //   },
  // }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      // ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor(private AngularEditorValidateService:AngularEditorValidateService, private ServiceSectorService :ServiceSectorService, private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private ServiceService:ServiceService) {
    this.InitForm()
    this.GetServiceSector()
   }
   GetServiceSector(){
    this.ServiceSectorService.GetServiceSectors().subscribe(res=>{
      this.ServiceSectors=res.data
    })
   }
   
  onSelect(event) {

    this.files.push(...event.addedFiles);
    this.HaveServiceImage=false
    this.ServiceImageView.push(this.files[0])
 
    if(this.files.length > 1){
      this.replaceFile();
    }
  
  }
  
  replaceFile() {
   this.files.splice(0,1);
   this.HaveServiceImage=false
   this.ServiceImageView.splice(0.1);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    if(this.ServiceData.logo){
          this.HaveServiceImage=true

    }
    this.ServiceImageView.splice(this.ServiceImageView.indexOf(event), 1);

  }
  
  
 ngOnInit(): void {
   if (this.route.snapshot.paramMap.get("id")) {
  
     this.id =this.route.snapshot.paramMap.get("id")
   this.ServiceService.GetServiceById(this.id).subscribe(async (res:any)=>{
   this.ServiceData=  await res.data;
  this.ServiceData.image=this.imgUrlServer+this.ServiceData.image

  if(this.ServiceData.image){
   this.HaveServiceImage=true
  }
  
   this.InitForm(this.ServiceData);
   })
     this.update = true;
   } else {
     this.InitForm()
     this.update = false;
   }
 }
 InitForm(Service?:Service) {
   this.InsertForm = this._formBuilder.group({
    TitleAr: [Service?.titleAr, Validators.required],
    Title: [Service?.title, Validators.required],
    Description: [Service?.description, Validators.required],
    DescriptionAr: [Service?.descriptionAr, Validators.required],
    VideoUrl: [Service?.videoUrl, Validators.required],
    ServiceSectorId: [Service?.serviceSectorId, Validators.required],
    Order: [Service?.order, Validators.required],

   });
   }
   get fc() {
     return this.InsertForm.controls;
   }
 onSubmit() {
  if(this.AngularEditorValidateService.AngularEditorValidate(this.InsertForm.get('Description'))){
    this.InsertForm.get('Description').setErrors({AngularEditorError:true})
  }
  if(this.AngularEditorValidateService.AngularEditorValidate(this.InsertForm.get('DescriptionAr'))){
    this.InsertForm.get('DescriptionAr').setErrors({AngularEditorError:true})
  }

   if (this.InsertForm.status == "VALID") {
   this.validated = false;
   if (this.update == true) {
   this.UpdateCompanyProduct();
   } else {
   this.InsertCompanyProduct();
   }
   } else {
   this.validated = true;
   }
   }
   UpdateCompanyProduct(){
     this.disableBtn=true;
     this.ServiceImageView.forEach(element => {
      this.ServiceForm.set('ServiceImage', element)
    })
     
    this.ServiceForm.set('Id',this.id)
   this.ServiceForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.ServiceForm.set('Title',this.InsertForm.get('Title').value)
     this.ServiceForm.set('VideoUrl',this.InsertForm.get('VideoUrl').value)
     this.ServiceForm.set('DescriptionAr',this.InsertForm.get('DescriptionAr').value)
     this.ServiceForm.set('Description',this.InsertForm.get('Description').value)
     this.ServiceForm.set('ServiceSectorId',this.InsertForm.get('ServiceSectorId').value)
     this.ServiceForm.set('Order',this.InsertForm.get('Order').value)
     this.ServiceService.UpdateService(this.ServiceForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم التعديل بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-service");
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
   
       );
   }
   InsertCompanyProduct(){
     this.disableBtn=true;
     this.ServiceImageView.forEach(element => {
      this.ServiceForm.set('ServiceImage', element)
    })
 
   this.ServiceForm.set('TitleAr',this.InsertForm.get('TitleAr').value)
     this.ServiceForm.set('Title',this.InsertForm.get('Title').value)
     this.ServiceForm.set('VideoUrl',this.InsertForm.get('VideoUrl').value)
     this.ServiceForm.set('DescriptionAr',this.InsertForm.get('DescriptionAr').value)
     this.ServiceForm.set('Description',this.InsertForm.get('Description').value)
     this.ServiceForm.set('ServiceSectorId',this.InsertForm.get('ServiceSectorId').value)
     this.ServiceForm.set('Order',this.InsertForm.get('Order').value)
 
     this.ServiceService.InsertService(this.ServiceForm).subscribe(
       (response) => {
       Swal.fire({
       icon: "success",
       title: "تم الحفظ بنجاح",
       showConfirmButton: false,
       timer: 1500,
       });
       this.disableBtn=false;
       this.router.navigateByUrl("content/admin/list-service");
 
     },
       (err) => {
         this.disableBtn=false;

         Swal.fire({
           icon: "error",
           title:err.error.message
           });
       }
             );
   }
}
