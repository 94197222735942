import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import {NewsletterService} from 'src/app/shared/API-Service/newsletter.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list-newsletter',
  templateUrl: './list-newsletter.component.html',
  styleUrls: ['./list-newsletter.component.scss']
})
export class ListNewsletterComponent extends BaseComponent implements OnInit{
  Newsletter: any;

  constructor(private router: Router,private NewsletterService:NewsletterService) { super()
  
    
  }

  ngOnInit(): void {
  this.GetNewsletterList();
  }

  AddNew(){
    this.router.navigateByUrl("content/admin/insert-news-letter");
  }
  pageChanged(event:any){
    this.pageNumber = event;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
this.GetNewsletterList();
   
  }
  update(id){
    this.router.navigate([`content/admin/update-service`, id]);
  }
  GetNewsletterList(){
    this.NewsletterService.GetNewsletterPaged(this.pager).subscribe(
      (res:any) => {
        this.Newsletter=res.data.items;
        this.totalCount = res.data.totalCount;
        
      },
      err => {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'خطأ',
        //   text: err.error,
        // })
      }
    )
  }
 
}
