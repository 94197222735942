import { Router } from '@angular/router';
// import { ChangePassWord } from '../../../shared//Models/change-pass-word';
import { UsersService } from 'src/app/shared/API-Service/users.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
//  modelChangepassword = new ChangePassWord
  public ChangePassForm: FormGroup;
  validated: boolean;

  constructor(private fb: FormBuilder,private router:Router,private UsersService:UsersService) {
    this.ChangePassForm = this.fb.group({
      currentPassword: ["", [Validators.required]],
      newPassword: ["", Validators.required],
    });
   }

  ngOnInit(): void {
  }

  changePassword(){
    Swal.fire({
      position: 'center',   
      title: 'هل تريد تغير كلمه السر ',
      showCancelButton: true,
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا',
      showLoaderOnConfirm: true,
      preConfirm: (phone) => {
        const data={
          "currentPassword": this.ChangePassForm.get('currentPassword').value,
          "newPassword": this.ChangePassForm.get('newPassword').value,
        }
        
       
          this.UsersService.ChangePassword(data).subscribe(response=>{
      if (response) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'تم تغير كلمه المرور',
          showConfirmButton: false,
          timer: 1500
        })
        localStorage.clear();

    this.router.navigate(["/login"]);
      }
    },err=>{
      Swal.fire({
        icon: 'error',
        title: 'رقم السر الحاليه خطا',
        text:'برجاء التأكد'
        
       
      })
     })
      }
    })
  
  }

submit(){
  if (this.ChangePassForm.status == "VALID") {
    this.validated = false;
    
    this.changePassword();
  
    } else {
    this.validated = true;
    }
}
  back(){
    history.back()
  }

}
