import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CareerRequest} from 'src/app/shared//Models/CareerRequest'
import Swal from 'sweetalert2';
import { CareerRequestService } from 'src/app/shared/API-Service/career-request.service';
import {CareerService} from 'src/app/shared/API-Service/career.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-insert-career-request',
  templateUrl: './insert-career-request.component.html',
  styleUrls: ['./insert-career-request.component.scss']
})
export class InsertCareerRequestComponent {
  InsertForm: FormGroup;
  CareerRequest=new CareerRequest()
  update: boolean;
  validated: boolean;
  CareerRequestData: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];
  ResumeDocView:any[]=[];
  CareerRequestForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveResumeDoc: boolean=false;
  Career:any[]=[]
  isImage: boolean;
  DocImage: any
  constructor(private http: HttpClient,private CareerRequestService :CareerRequestService, private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private CareerService:CareerService) {
    this.InitForm();
    this.GetServiceSector();
   }
 GetServiceSector(){
  this.CareerService.GetCareers().subscribe(res=>{
    this.Career=res.data
  })
 }
 
   onSelect(event) {

     this.files.push(...event.addedFiles);
     this.HaveResumeDoc=false
     this.ResumeDocView.push(this.files[0])
  console.log(this.files)
     if(this.files.length > 1){
       this.replaceFile();
     }
   if(this.files[0]?.type.includes('image')){
    this.isImage=true;
   }
   else{
    this.isImage=false
   }
   }
   
   replaceFile() {
    this.files.splice(0,1);
    this.HaveResumeDoc=false
    this.ResumeDocView.splice(0.1);
    if(this.files[0]?.type.includes('image')){
      this.isImage=true;
     }
     else{
      this.isImage=false
     }
   }
 
   onRemove(event) {
     this.files.splice(this.files.indexOf(event), 1);
     if(this.CareerRequestData?.resume){
           this.HaveResumeDoc=true

     }
     this.ResumeDocView.splice(this.ResumeDocView.indexOf(event), 1);
     if(this.files[0]?.type.includes('image')){
      this.isImage=true;
     }
     else{
      this.isImage=false
     }
   }
 
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.CareerRequestService.GetCareerRequestById(this.id).subscribe(async (res:any)=>{
    this.CareerRequestData=  await res.data;
   this.CareerRequestData.resume=this.imgUrlServer+this.CareerRequestData.resume
   if(this.CareerRequestData.resume){
    this.HaveResumeDoc=true
    this.isImage=true;
   }
    this.InitForm(this.CareerRequestData);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(CareerRequests?:CareerRequest) {
    this.InsertForm = this._formBuilder.group({
     Name: [CareerRequests?.name, Validators.required],
     Mobile: [CareerRequests?.mobile, Validators.required],
     Email: [CareerRequests?.email, Validators.required],
     CareerId: [CareerRequests?.careerId, Validators.required],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    if (this.update == true) {
    this.UpdateCareerRequest();
    } else {
    this.InsertCareerRequest();
    }
    } else {
    this.validated = true;
    }
    }
    UpdateCareerRequest(){
      this.disableBtn=true;
 
      this.ResumeDocView.forEach(element => {
        this.CareerRequestForm.set('ResumeDoc', element)
      })
      this.CareerRequestForm.set('Id',this.id)
      this.CareerRequestForm.set('Name',this.InsertForm.get('Name').value)
      this.CareerRequestForm.set('Mobile',this.InsertForm.get('Mobile').value)
      this.CareerRequestForm.set('Email',this.InsertForm.get('Email').value)
      this.CareerRequestForm.set('CareerId',this.InsertForm.get('CareerId').value)
      this.CareerRequestService.UpdateCareerRequest(this.CareerRequestForm).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم التعديل بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-Career-request");
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
    
        );
    }
    InsertCareerRequest(){
      this.disableBtn=true;
      this.ResumeDocView.forEach(element => {
        this.CareerRequestForm.set('ResumeDoc', element)
      })
      this.CareerRequestForm.set('Name',this.InsertForm.get('Name').value)
      this.CareerRequestForm.set('Mobile',this.InsertForm.get('Mobile').value)
      this.CareerRequestForm.set('Email',this.InsertForm.get('Email').value)
      this.CareerRequestForm.set('CareerId',this.InsertForm.get('CareerId').value)


      this.CareerRequestService.InsertCareerRequest(this.CareerRequestForm).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-Career-request");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
  
}
