import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Careers} from 'src/app/shared//Models/Careers'
import Swal from 'sweetalert2';
import { CareerService } from 'src/app/shared/API-Service/career.service';
import {ServiceSectorService} from 'src/app/shared/API-Service/service-sector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-insert-career',
  templateUrl: './insert-career.component.html',
  styleUrls: ['./insert-career.component.scss']
})
export class InsertCareerComponent {
  InsertForm: FormGroup;
  Careers=new Careers()
  maxDate: Date;
  update: boolean;
  dropdownList: any = [];
  selectedItems: any = [];
  companyTypeId: any = [];
  returnedObject: any = {};
  validated: boolean;
  Career: any;
  id: any;
  disableBtn:boolean
  files: File[] = [];
  CareersImageView:any[]=[];
  CareersForm: any = new FormData();
  imgUrlServer=environment.Server_URL_Image+'/'
  HaveCareersImage: boolean=false;
  ServiceSectors:any[]=[]
  constructor(private ServiceSectorService :ServiceSectorService, private route: ActivatedRoute,private router: Router,private _formBuilder: FormBuilder,private CareerService:CareerService) {
    this.InitForm();
    this.GetServiceSector();
   }
 GetServiceSector(){
  this.ServiceSectorService.GetServiceSectors().subscribe(res=>{
    this.ServiceSectors=res.data
  })
 }
   onSelect(event) {

     this.files.push(...event.addedFiles);
     this.HaveCareersImage=false
     this.CareersImageView.push(this.files[0])
  
     if(this.files.length > 1){
       this.replaceFile();
     }
   
   }
   
   replaceFile() {
    this.files.splice(0,1);
    this.HaveCareersImage=false
    this.CareersImageView.splice(0.1);
   }
 
   onRemove(event) {
     this.files.splice(this.files.indexOf(event), 1);
     if(this.Career.logo){
           this.HaveCareersImage=true

     }
     this.CareersImageView.splice(this.CareersImageView.indexOf(event), 1);

   }
 
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
   
      this.id =this.route.snapshot.paramMap.get("id")
    this.CareerService.GetCareerById(this.id).subscribe(async (res:any)=>{
    this.Career=  await res.data;
   this.Career.image=this.imgUrlServer+this.Career.image
   if(this.Career.image){
    this.HaveCareersImage=true
   }
    this.InitForm(this.Career);
    })
      this.update = true;
    } else {
      this.InitForm()
      this.update = false;
    }
  }
  InitForm(Career?:Careers) {
    this.InsertForm = this._formBuilder.group({
     JobTitleAr: [Career?.jobTitleAr, Validators.required],
     JobTitle: [Career?.jobTitle, Validators.required],
     JobRequirementsAr: [Career?.jobRequirementsAr, Validators.required],
     JobRequirements: [Career?.jobRequirements, Validators.required],
     NumOfVacancies: [Career?.numOfVacancies, Validators.required],
     ServiceSectorId: [Career?.serviceSectorId, Validators.required],
     Order: [Career?.order, Validators.required],
     WebsiteVisible: [Career?.websiteVisible, ],
    });
    }
    get fc() {
      return this.InsertForm.controls;
    }
  onSubmit() {
    
    if (this.InsertForm.status == "VALID") {
    this.validated = false;
    if (this.update == true) {
    this.UpdateCareer();
    } else {
    this.InsertCareer();
    }
    } else {
    this.validated = true;
    }
    }
    UpdateCareer(){
      this.disableBtn=true;
      this.CareersImageView.forEach(element => {
        this.CareersForm.set('CareerImage', element)
      })
      this.CareersForm.set('Id',this.id)
      this.CareersForm.set('JobTitleAr',this.InsertForm.get('JobTitleAr').value)
      this.CareersForm.set('JobTitle',this.InsertForm.get('JobTitle').value)
      this.CareersForm.set('JobRequirementsAr',this.InsertForm.get('JobRequirementsAr').value)
      this.CareersForm.set('JobRequirements',this.InsertForm.get('JobRequirements').value)
      this.CareersForm.set('NumOfVacancies',this.InsertForm.get('NumOfVacancies').value)
      this.CareersForm.set('ServiceSectorId',this.InsertForm.get('ServiceSectorId').value)
      this.CareersForm.set('WebsiteVisible',this.InsertForm.get('WebsiteVisible').value)
      this.CareersForm.set('Order',this.InsertForm.get('Order').value)
      this.CareerService.UpdateCareer(this.CareersForm).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم التعديل بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-career");
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
    
        );
    }
    InsertCareer(){
      this.disableBtn=true;
      this.CareersImageView.forEach(element => {
        this.CareersForm.set('CareerImage', element)
      })
      this.CareersForm.set('JobTitleAr',this.InsertForm.get('JobTitleAr').value)
      this.CareersForm.set('JobTitle',this.InsertForm.get('JobTitle').value)
      this.CareersForm.set('JobRequirementsAr',this.InsertForm.get('JobRequirementsAr').value)
      this.CareersForm.set('JobRequirements',this.InsertForm.get('JobRequirements').value)
      this.CareersForm.set('NumOfVacancies',this.InsertForm.get('NumOfVacancies').value)
      this.CareersForm.set('ServiceSectorId',this.InsertForm.get('ServiceSectorId').value)
      this.CareersForm.set('WebsiteVisible',this.InsertForm.get('WebsiteVisible').value)
      this.CareersForm.set('Order',this.InsertForm.get('Order').value)

      this.CareerService.InsertCareer(this.CareersForm).subscribe(
        (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الحفظ بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.disableBtn=false;
        this.router.navigateByUrl("content/admin/list-career");
  
      },
        (err) => {
          this.disableBtn=false;

          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        }
              );
    }
  
}
