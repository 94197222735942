import { Component } from '@angular/core';

@Component({
  selector: 'app-insert-contact-inquiry',
  templateUrl: './insert-contact-inquiry.component.html',
  styleUrls: ['./insert-contact-inquiry.component.scss']
})
export class InsertContactInquiryComponent {

}
