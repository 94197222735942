<app-breadcrumb [title]="'الاستفسارات'" ></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header px-0" style="padding-bottom: 5px;">
              <div class="row m-0" >
                  <div class="col-12 col-md-9">
                    <h5 class="py-2">الاستفسارات</h5>
                    <span  >  إعدادت الاستفسارات موجودة بالنظام ( تغير حالة - حذف ) </span>
                  </div>
                  <div class="col-6 mt-3">
                      <!-- <div appActionNew class="addnew-btn" (click)="AddNew()">
                           
                            <i class="fa fa-plus "></i> <span class="mt-1">جديد</span>
                            <span class="tooltiptext">  إضافة ظلب توظيف جديد</span>
                      </div> -->
                      <label>الحالة</label>
                      <ng-select   (change)="chenged($event?.id)" [items]="States" placeholder="Select"
                      class="js-example-placeholder-multiple" bindLabel="title"
                      bindValue="id" [(ngModel)]="StatesValue"></ng-select>
                  </div>
              </div>
          
          </div>
          <div class="table-responsive ">
            <table class="table  table-striped" style="width: 100%;">
              <thead>
                <tr class="bg-dark ">
                  <th scope="col" class="font-style-header" >#</th>
                  <th scope="col" class="font-style-header" > الاسم</th>
                  <th scope="col" class="font-style-header" > الموبايل</th>
                  <th scope="col" class="font-style-header" > البريد الالكتروني</th>
                  <th scope="col" class="font-style-header" > الرسالة</th>
                  <th scope="col" class="font-style-header" > تاريخ الانشاء</th>
                  <th scope="col" class="font-style-header" > الحالة</th>
                  <th scope="col" class="font-style-header"  >  </th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let item of ContactInquiry; let i = index;">
                  <td scope="row" class="font-style">{{i+1}}</td>
                  <td  class="font-style">{{item.name}}</td>
                  <td  class="font-style">{{item.mobile}}</td>
                  <td  class="font-style">{{item.email}}</td>
                  <td  class="font-style">{{item.message}}</td>
                  <td  class="font-style">{{item.creationDate | date:"dd/MM/yyyy"}}</td>
                  <td  class="font-style" >   <div class="form-check checkbox checkbox-solid-danger">
                    <input #checkboxRef class="form-check-input" [id]="item.id" type="checkbox" (change)="onCheckboxChange(item,i)" [disabled]="item.isViewed" [checked]="item.isViewed">
                    <label class="form-check-label" [for]="item.id"></label>
                  </div></td>

                  <td class="font-style" style="text-align: end; width: 8%;" >
                                          <!-- <button class="border-0" appActionEdit href=""style="color: rgba(35, 118, 241, 0.667);padding: 7px; background-color: transparent;"(click)="update(item.id)">
                          <i class="fa fa-pencil"></i>
                        </button> -->
                        <button class="border-0" appActionDelete href=""style="color: red; padding: 7px; background-color: transparent;" (click)="Delete(item.id)">
                          <i class="fa fa-trash"></i>
                        </button>
                     
                    </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center">
                <ngb-pagination class="ms-3"   (pageChange)="pageChanged($event)" [collectionSize]="totalCount" [(page)]="pageNumber"
                [pageSize]="pager.maxResultCount"> </ngb-pagination>

              <!-- <pagination boundaryLinks="true" maxSize="20" totalItems="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                firstText="&laquo;" lastText="&raquo;" itemsPerPage="pager.maxResultCount" [(ngModel)]="pageNumber"
                (pageChanged)="pageChanged($event)"> -->
              <!-- </pagination> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>