import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class ServiceSectorService {
  constructor(private http:HttpClient,private router:Router) { }
 
  GetServiceSectors(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ServiceSector/GetDB`);
  }
  GetServiceSectorsPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/ServiceSector/GetPagedDB`,data);
  }
  GetServiceSectorById(ServiceSectorId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ServiceSector/GetByIdDB?Id=${ServiceSectorId}`);
  }
  InsertServiceSector(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/ServiceSector/Create`,Data);
  }
  UpdateServiceSector(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/ServiceSector/Update`,Data);
  }
  
  DeleteServiceSector(Id:any): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/ServiceSector/Delete?id=${Id}`);
  }
}
