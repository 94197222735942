import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericResponse } from '../Models/GenericResponse';
import { GenericResponseSingle } from '../Models/GenericResponseSingle';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http:HttpClient,private router:Router) { }
 
  GetUser(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/User/GetDB`);
  }
  GetUserPaged(data:any): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.Server_URL}/User/GetPagedDB`,data);
  }
  GetAllUsers(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/User/GetAllUsers`);
  }
  GetUserById(userId): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/User/GetUserById?id=${userId}`);
  }
  InsertUser(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/User/Create`,Data);
  }

  ChangePassword(Data): Observable<GenericResponseSingle<any>> {
    return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/User/ChangePassword`,Data);
  }
}
