<app-breadcrumb [title]="'الاخبار'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important ">
          <ng-template>
            <h5 class="pb-2">الاخبار</h5>
            يمكنك إضافة وتعديل الاخبار
          </ng-template>
        </div>
        <div class="card-body">
          <form class="needs-validation" [formGroup]="InsertForm" (ngSubmit)="onSubmit()" novalidate
            [ngClass]="{'was-validated': validated}">

            <div class="row">
              <div class="col-6 mb-3">

                <label for="validationCustom01"> العنوان </label>
                <input class="form-control" id="validationCustom01" type="text" placeholder="" required=""
                  formControlName="Subject" />




              </div>
           
             
              <div class="col-6 mb-3">

                <label for="validationCustom01"> الموضوع</label>
                  <angular-editor formControlName="Body" [config]="config" required=""></angular-editor>




              </div>
    
             
            </div>

          

            <div  >
              <button class="btn btn-primary pull-right" type="button" (click)="onSubmit()" [disabled]="disableBtn">
                حفظ
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>